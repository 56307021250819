// @flow
import React from 'react'
import SocialLink from './social-link'
import Row from './row'

const Social = () => (
  <Row>
    <div className="title">
      <h3>follow us</h3>
    </div>
    <div>
      <SocialLink link="https://www.facebook.com/dr0p.network" network="fb" />
    </div>
    <div>
      <SocialLink link="https://twitter.com/dr0p_me" network="twt" />
    </div>
    <div>
      <SocialLink link="https://soundcloud.com/dr0p-me" network="snd" />
    </div>
    <div>
      <SocialLink link="https://www.instagram.com/dr0p_me" network="inst" />
    </div>
    <div>
      <SocialLink link="http://medium.dr0p.me" network="md" />
    </div>
    <style jsx>{`
      div {
        display: flex;
        padding: 0 12px;
      }

      h3 {
        opacity: 0.8;
      }

      @media only screen and (max-width: 600px) {
        .title {
          display: none;
        }
      }
    `}</style>
  </Row>
)

export default Social

// @flow
import React from 'react'
import { Link } from 'gatsby'

const Space = () => (
  <span>
    |
    <style jsx>{`
      span {
        margin: 0 12px;
      }
    `}</style>
  </span>
)

type PrevProps = {
  link: {
    frontmatter: {
      path: string,
      title: string,
      artist: string,
    },
  },
  hoverText: string,
  position?: 'left' | 'right',
}

const Linker = ({ link, hoverText, position = 'left' }: PrevProps) => (
  <div>
    <Link to={link.frontmatter.path}>
      {link.frontmatter.artist}
      <span>{hoverText}</span>
    </Link>
    <style jsx>{`
      div {
        position: relative;
        z-index: 1;
      }

      span {
        position: absolute;
        top: 0;
        ${position === 'left' ? 'left: 0px;' : 'right: 0px;'}
        color: white;
        font-size: 12px;
        font-weight: bold;
        transform: translateY(0%);
        opacity: 0;
        transition: all 250ms ease-out;
        z-index: 10;
        white-space: nowrap;
      }

      div:hover span {
        transform: translateY(150%);
        opacity: 1;
      }

      div :global(a) {
        color: white;
        text-decoration: none;
        opacity: 0.8;
        transition: opacity 250ms ease-out;
      }

      div:hover :global(a),
      div :global(a:hover) {
        opacity: 1;
      }

      @media only screen and (max-width: 919px) {
        div :global(a) {
          opacity: 1;
        }

        div span {
          transform: translateY(150%);
          opacity: 1;
        }
      }
    `}</style>
  </div>
)

type Props = {
  prev?: *,
  next?: *,
}

const Pager = ({ prev, next }: Props) => (
  <div className="bottom">
    {next ? <Linker link={next} hoverText={`${next.frontmatter.title}`} position="right" /> : null}
    {next && prev ? <Space /> : null}
    {prev ? <Linker link={prev} hoverText={`${prev.frontmatter.title}`} /> : null}

    <style jsx>{`
      .bottom {
        margin-top: 32px;
        display: flex;
        flex-direction: row;
      }

      .bottom :global(a) {
        color: white;
        text-decoration: none;
        opacity: 0.8;
        transition: opacity 250ms ease-out;
      }

      .bottom :global(a:hover) {
        opacity: 1;
      }

      @media only screen and (max-width: 600px) {
        .bottom {
          opacity: 1;
        }
      }
    `}</style>
  </div>
)

export default Pager

// @flow
import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Logo from './icons/logo'
import NavBar from './navbar'

import '../styles/normalize.css'

const query = graphql`
  query siteMetaData {
    site {
      siteMetadata {
        title
        subtitle
        keywords
      }
    }
  }
`

type Props = {
  title?: string,
  description?: string,
  keywords?: string[],
}

const Header = ({ title = '', description = '', keywords = [] }: Props) => (
  <StaticQuery
    query={query}
    render={data => (
      <header>
        <Helmet>
          <html lang="en" />
          <title>{`${data.site.siteMetadata.title}${title ? ` - ${title}` : ''}`}</title>
          <meta charSet="utf8" />
          <meta name="description" content={description || data.site.siteMetadata.subtitle} />
          <meta
            name="keywords"
            content={
              keywords.length ? keywords.join(', ') : data.site.siteMetadata.keywords.join(', ')
            }
          />
        </Helmet>
        <div className="container">
          <Link to="/" className="logo">
            <Logo className="svg" />
          </Link>
        </div>

        <NavBar />

        <style jsx>{`
          .container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 120px;
            margin-bottom: 24px;
          }

          header :global(.logo) {
            display: block;
            width: 100%;
            max-width: 200px;
          }

          header :global(.svg) {
            opacity: 0.95;
            transition: opacity 250ms ease-out;
          }

          header :global(.svg:hover) {
            opacity: 1;
          }
        `}</style>
      </header>
    )}
  />
)

export default Header

// @flow
import React from 'react'

type Props = {
  children: React$Node,
  noMargin?: boolean,
  center?: boolean,
  className?: string,
}

const Row = ({ children, noMargin = false, center = false, className = '' }: Props) => (
  <section className={className}>
    {children}
    <style jsx>{`
      section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: ${center ? 'center' : 'flex-start'};
        width: 100%;
        margin: ${!noMargin ? 24 : 0}px 0;
      }
    `}</style>
  </section>
)

export default Row

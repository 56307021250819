// @flow
import React from 'react'
import Header from './header'
import Footer from './footer'
import Container from './container'

type Props = {
  children: React$Node,
  centerContent?: boolean,
  row?: boolean,
  meta?: {
    title?: string,
    desscription?: string,
    keywords?: string[],
  },
}

const Layout = ({ children, centerContent = false, row = false, meta }: Props) => (
  <div className="page">
    <Header {...meta} />
    <Container center={centerContent} row={row}>
      {children}
    </Container>
    <Footer />
    <style global jsx>{`
      body,
      html {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 100vh;
        justify-content: stretch;
      }
      body {
        background-image: linear-gradient(to top left, #f43b47 0%, #453a94 100%);
        background-repeat: no-repeat;
        background-attachment: fixed;
      }

      body > div,
      body > div > div {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    `}</style>

    <style jsx>{`
      .page {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    `}</style>
  </div>
)

export default Layout

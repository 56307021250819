// @flow
import React from 'react'

type Props = {
  url: string,
  title: string,
  small?: boolean,
}

const EmbedSmall = ({ url, title, small = false }: Props) => (
  <div>
    <iframe
      title={title}
      width="100%"
      height={small ? '400' : '166'}
      scrolling="no"
      frameBorder="no"
      allow="autoplay"
      src={small ? `${url}&visual=true` : url}
    />
    <style jsx>{`
      div {
        display: flex;
        flex: 1;
        align-self: stretch;
      }

      iframe {
        border: 25px solid rgba(255, 255, 255, 0.3);
      }
    `}</style>
  </div>
)

export default EmbedSmall

// @flow
import React from 'react'
import { Link } from 'gatsby'

const NavBar = () => (
  <nav>
    <Link to="/guest-mix" className="link" activeClassName="active">
      Guest Mixes
    </Link>
    <Link to="/special-edition" className="link" activeClassName="active">
      Special Edition
    </Link>

    <style jsx>{`
      nav {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      nav :global(.link) {
        color: white;
        font-size: 20px;
        font-family: Open Sans;
        font-weight: 600;
        text-decoration: none;
        text-transform: uppercase;
        opacity: 0.5;
        transition: opacity 250ms ease-out;
        position: relative;
        padding: 0 10px 10px;
      }

      nav :global(.active),
      nav :global(.link:hover) {
        opacity: 1;
      }

      nav :global(.link:after) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        background: white;
        height: 4px;
        transform: scaleX(0.3);
        transition: transform 250ms ease-out;
      }

      nav :global(.link:hover:after),
      nav :global(.active:after) {
        transform: scaleX(0.75);
      }
    `}</style>
  </nav>
)

export default NavBar

// @flow
/* eslint  import/no-dynamic-require: 0, global-require: 0 */
import React, { Component } from 'react'
import { graphql } from 'gatsby'
import uuid from 'uuid/v4'
import Layout from '../components/layout'
import Container from '../components/container'
import Row from '../components/row'
import EmbedSmall from '../components/embed-small'
import Poster from '../components/poster'
import Post from '../components/post'
import Track from '../components/track'
import Pager from '../components/pager'
import WithSize from '../utils/with-size'

type Props = {
  data: {
    markdownRemark: {
      html: *,
      frontmatter: {
        title: string,
        soundcloud: string,
        artist: string,
        tracklist: string[],
        tags: string[],
        categories: string[],
        number: string,
      },
    },
  },
  pageContext: {
    prev?: *,
    next?: *,
  },
}

type State = {}

const getFrontmatter = data => data.markdownRemark.frontmatter
const getHtml = data => data.markdownRemark.html

class MixTemplate extends Component<Props, State> {
  renderLarge = (post: React$Node) => {
    const { title, tracklist, categories, number } = getFrontmatter(this.props.data)
    const type = categories.find(cat => cat !== 'mix')
    return (
      <>
        <Container>
          <Row>
            <Poster
              src={require(`../images/covers/${type === 'guest' ? 'gm' : 'se'}-${number}.jpg`)}
              title={title}
            />
            <Container flex={2} noMargin>
              {post}
            </Container>
          </Row>
          {tracklist.length ? (
            <>
              <h2 style={{ alignSelf: 'flex-start' }}>Tracklist</h2>
              {tracklist.map(track => (
                <Track key={uuid()}>{track}</Track>
              ))}
            </>
          ) : null}
        </Container>
      </>
    )
  }

  renderSmall = (post: React$Node) => {
    const { title, tracklist, categories, number } = getFrontmatter(this.props.data)
    const type = categories.find(cat => cat !== 'mix')
    return (
      <>
        <Container>
          <Container noMargin>{post}</Container>
          <Poster
            src={require(`../images/covers/${type === 'guest' ? 'gm' : 'se'}-${number}.jpg`)}
            title={title}
          />
          {tracklist.length ? (
            <Container>
              <h2>Tracklist</h2>
              {tracklist.map(track => (
                <Track key={uuid()}>{track}</Track>
              ))}
            </Container>
          ) : null}
        </Container>
      </>
    )
  }

  render() {
    const { title, soundcloud, artist, tags } = getFrontmatter(this.props.data)

    const html = getHtml(this.props.data)
    const { next, prev } = this.props.pageContext
    return (
      <Layout meta={{ title: artist, description: title, keywords: tags }}>
        <WithSize>
          {({ small }) => {
            const Body = () =>
              small
                ? this.renderSmall(
                    <div className="post">
                      <Post html={html} />
                    </div>,
                  )
                : this.renderLarge(
                    <div className="post">
                      <Post html={html} />
                    </div>,
                  )

            return (
              <>
                <Container>
                  <h2>{title}</h2>
                  <h1>{artist}</h1>
                  <Container noMargin={small}>
                    <EmbedSmall url={soundcloud} title={title} small={small} />
                  </Container>
                </Container>
                <Body />
                <Pager next={next} prev={prev} />
              </>
            )
          }}
        </WithSize>
        <style jsx>{`
          img {
            width: 100%;
          }

          .post {
            width: 100%;
            padding-left: 32px;
            padding-right: 8px;
          }

          @media only screen and (max-width: 600px) {
            .post {
              padding: 0 16px;
            }

            h2,
            :global(iframe) {
              margin-bottom: 0;
            }
          }
          @media only screen and (min-width: 600px) and (max-width: 929px) {
          }
        `}</style>
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        soundcloud
        categories
        artist
        tracklist
        tags
        number
      }
    }
  }
`

export default MixTemplate

// @flow
import React from 'react'

type Props = {
  children: React$Node,
}

const Track = ({ children }: Props) => (
  <div>
    {children}
    <style jsx>{`
      div {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        align-self: stretch;
        padding: 2px 0;
        margin-bottom: 2px;
        color: white;
        text-align: center;
        background: rgba(255, 255, 255, 0);
        transition: background 250ms ease-out;
      }

      div:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      @media only screen and (max-width: 600px) {
        div {
          padding: 8px;
          transition: none;
        }

        div:hover {
          background: rgba(255, 255, 255, 0);
        }
      }

      @media only screen and (min-width: 600px) and (max-width: 919px) {
        div {
          padding: 8px;
        }
      }
    `}</style>
  </div>
)

export default Track

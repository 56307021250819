// @flow
import React from 'react'
import Container from './container'

type Props = {
  src: string,
  title: string,
}

const Poster = ({ src, title }: Props) => (
  <Container noMargin>
    <div>
      <img src={src} alt={title} />
    </div>

    <style jsx>{`
      img {
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        border: 25px solid rgba(255, 255, 255, 0.3);
      }
    `}</style>
  </Container>
)

export default Poster

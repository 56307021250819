// @flow
/* globals window */
import { Component } from 'react'

type Props = {
  children: ({ small: boolean, medium: boolean }) => React$Node,
}

type State = {
  small: boolean,
  medium: boolean,
}

let mounted = false
class WithSize extends Component<Props, State> {
  mqlMedium: MediaQueryList

  mqlSmall: MediaQueryList

  state = {
    small: false,
    medium: false,
  }

  componentDidMount() {
    mounted = true
    this.mqlMedium = window.matchMedia('(min-width: 600px) and (max-width: 919px)')
    this.mqlSmall = window.matchMedia('(max-width: 599px)')

    this.mqlMedium.addListener(this.updateMedium)
    this.mqlSmall.addListener(this.updateSmall)

    this.setState({ small: this.mqlSmall.matches, medium: this.mqlMedium.matches })
  }

  componentWillUnmount() {
    mounted = false
    if (this.mqlMedium) this.mqlMedium.removeListener(this.updateMedium)
    if (this.mqlSmall) this.mqlSmall.removeListener(this.updateSmall)
  }

  safeSetState = (state: { [string]: boolean }) => {
    if (mounted) {
      this.setState(state)
    }
  }

  updateSmall = () => {
    this.safeSetState({ small: this.mqlSmall.matches })
  }

  updateMedium = () => {
    this.safeSetState({ medium: this.mqlMedium.matches })
  }

  render() {
    const { small, medium } = this.state
    return this.props.children({ small, medium })
  }
}

export default WithSize

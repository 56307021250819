// @flow
import React from 'react'
import Social from './social'

const Footer = () => (
  <footer>
    <Social />
    <style jsx>{`
      footer {
      }
    `}</style>
  </footer>
)

export default Footer

// @flow
import React from 'react'

type Props = {
  children: React$Node,
  flex?: number,
  noMargin?: boolean,
  center?: boolean,
  className?: string,
  row?: boolean,
}

const Container = ({
  children,
  flex = 1,
  noMargin = false,
  center = false,
  className = '',
  row = false,
}: Props) => (
  <section className={className}>
    {children}

    <style jsx>{`
      section {
        display: flex;
        flex: ${flex};
        flex-direction: ${row ? 'row' : 'column'};
        ${row ? 'flex-wrap: wrap;' : ''};
        align-items: ${row ? 'flex-start' : 'center'};
        justify-content: ${center ? 'center' : 'flex-start'};
        width: 100%;
        max-width: 1200px;
        margin: ${!noMargin ? 24 : 0}px auto;
      }
    `}</style>
  </section>
)

export default Container

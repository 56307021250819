// @flow
import React from 'react'

type Props = {
  html: string,
}

const Post = ({ html }: Props) => (
  <div className="post">
    <div dangerouslySetInnerHTML={{ __html: html }} /> {/* eslint-disable-line */}
    <style jsx>{`
      .post :global(a) {
        color: white;
        font-weight: bold;
        text-decoration: none;
        opacity: 0.8;
        transition: opacity 250ms ease-out;
      }

      .post :global(a:hover) {
        opacity: 1;
      }
    `}</style>
  </div>
)

export default Post

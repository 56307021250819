// @flow
import React from 'react'

type Props = {
  fill?: string,
}

const Soundcloud = ({ fill }: Props) => (
  <svg viewBox="0 -121 512 512">
    <path
      fill={fill || 'white'}
      d="M180 0h30v270h-30zm0 0M120 90h30v180h-30zm0 0M60 60h30v210H60zm0 0M0 150h30v120H0zm0 0M413.457 90.309C394.723 36.489 343.141 0 285 0a136.321 136.321 0 0 0-33.723 4.246L240 7.148V270h182c49.629 0 90-40.371 90-90 .016-52.133-46.57-93.723-98.543-89.691zm0 0"
    />
  </svg>
)

export default Soundcloud

// @flow
import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import Facebook from './icons/facebook'
import Soundcloud from './icons/soundcloud'
import Twitter from './icons/twitter'
import Instagram from './icons/instagram'
import Medium from './icons/medium'

type Props = {
  network: 'fb' | 'snd' | 'twt' | 'inst' | 'md',
  link: string,
}

const networks = {
  fb: { title: 'facebook page', Comp: Facebook },
  snd: { title: 'soundcloud profile', Comp: Soundcloud },
  twt: { title: 'twitter', Comp: Twitter },
  inst: { title: 'instagram', Comp: Instagram },
  md: { title: 'medium articles', Comp: Medium },
}

const SocialLink = (props: Props) => {
  const { network, link } = props
  const curr = networks[network]
  return (
    <OutboundLink
      className="socialLink"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      title={curr.title}
    >
      <curr.Comp />
      <style jsx>{`
        :global(.socialLink) {
          height: auto;
          width: 24px;
          opacity: 0.5;
          transition: opacity 250ms ease-out;
        }

        :global(.socialLink:hover) {
          opacity: 1;
        }
      `}</style>
    </OutboundLink>
  )
}

export default SocialLink
